<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.procedimentos_eletronicos.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.nome"
      class="col-12 col-md-6"
      :label="$t('modulos.procedimentos_eletronicos.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.revisao"
      class="col-12 col-md-6"
      :label="$t('modulos.procedimentos_eletronicos.filtro.revisao')"
      type="number"
      em-filtro
    />
    <input-select
      v-model="filtro.localExecucaoId"
      :options="opcoes.localExecucao"
      class="col-12 col-md-6"
      :label="$t('modulos.procedimentos_eletronicos.filtro.local')"
      :placeholder="$t('geral.inputs.selecione')"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';
import LocalExecucaoService from '@common/services/cadastros/LocalExecucaoService';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        localExecucao: [],
      },
    };
  },
  mounted() {
    this.buscarLocalExecucao();
  },
  methods: {
    buscarLocalExecucao: function () {
      LocalExecucaoService.listar({}).then((res) => {
        this.opcoes.localExecucao = new DropdownModel(res.data);
      });
    },
  },
};
</script>
