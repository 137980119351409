<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.procedimentos_eletronicos.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.procedimentos_eletronicos.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('ProcedimentoEletronico', 'Inserir')"
      :sem-filtro="!buscarPermissao('ProcedimentoEletronico', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>

    <botao-padrao
      v-if="podeExcluir && buscarPermissao('ProcedimentoEletronico', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>

    <modal-padrao
      ref="modal-clientes"
      :max-width="'90%'"
      :titulo="$t('componentes.input_cliente.titulo_modal')"
      :titulo-ok="$t('geral.botoes.confirmar')"
      @ok="copiarProcedimento"
    >
      <modal-clientes
        ref="modal-clientes-filho"
        v-model="clienteSelecionado"
        :multiplos="false"
      />
    </modal-padrao>

    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      :mostrar-seletor="false"
      mostrar-seletor-check-box
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:seletor="{ slotProps }">
        <div>
          <v-simple-checkbox
            v-if="
              podeSelecionar(slotProps.data) &&
                buscarPermissao('ProcedimentoEletronico', 'Excluir')
            "
            v-model="slotProps.data.isSelected"
            @input="
              selecionarProcedimentoEletronico(
                slotProps.data,
                slotProps.data.isSelected
              )
            "
          />
        </div>
      </template>
      <template v-slot:revisao="{ slotProps }">
        <div class="alinhar-direita">
          {{ slotProps.data.revisao }}
        </div>
      </template>
      <template v-slot:revisaoPublicada="{ slotProps }">
        <div class="alinhar-direita">
          {{ slotProps.data.revisaoPublicada }}
        </div>
      </template>
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="buscarPermissao('ProcedimentoEletronico', 'Editar')"
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('ProcedimentoEletronico', 'AssociarUsuario')"
            @click="abrirUsuarios(slotProps.data)"
          >
            {{
              $t('modulos.procedimentos_eletronicos.botoes.associar_usuarios')
            }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('ProcedimentoEletronico', 'Visualizar')"
            @click="abrirRevisoes(slotProps.data)"
          >
            {{
              $t('modulos.procedimentos_eletronicos.botoes.visualizar_revisoes')
            }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('ProcedimentoEletronico', 'Duplicar')"
            @click="duplicarProcedimentoEletronico(slotProps.data)"
          >
            {{ $t('modulos.procedimentos_eletronicos.botoes.duplicar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('ProcedimentoEletronico', 'Visualizar')"
            @click="desenharCertificado(slotProps.data)"
          >
            {{
              $t(
                'modulos.procedimentos_eletronicos.botoes.desenhar_certificado'
              )
            }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="
              clienteConta &&
                buscarPermissao('ProcedimentoEletronico', 'CriarCopiaParaCliente')
            "
            @click="abrirCopiarProcedimento(slotProps.data)"
          >
            {{
              $t(
                'modulos.procedimentos_eletronicos.botoes.copiar_procedimento_cliente'
              )
            }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>

<script>
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import ProcedimentoEletronicoService from '@/common/services/cadastros/ProcedimentoEletronicoService';
import helpers from '@/common/utils/helpers';
import Filtro from './components/filtro.vue';
import ModalClientes from '@/components/inputs/especificos/InputCliente/modal.vue';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
    ModalClientes,
  },
  data() {
    return {
      clienteSelecionado: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.procedimentos_eletronicos.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.procedimentos_eletronicos.tabela.nome'),
            sortable: true,
          },
          {
            value: 'locaisExecucoes',
            text: this.$t('modulos.procedimentos_eletronicos.tabela.local'),
            sortable: true,
            formatter: (value) => {
              if (Array.isArray(value)) {
                if (value.length > 1) {
                  return value.length;
                }
                return value[0]?.nome;
              }
              return value?.nome;
            },
          },
          {
            value:
              'lastProcedimentoEletronicoUltimaRevisaoViewModel.revisaoPublicada',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela.revisao_publicada'
            ),
            sortable: true,
            formatter: (v) => {
              if (v != null) return v;
            },
          },
          {
            value:
              'lastProcedimentoEletronicoUltimaRevisaoViewModel.dataPublicacao',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela.publicada_em'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    clienteConta: function () {
      return this.$store.getters['Autenticacao/participanteLogadoConta'];
    },
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.procedimentos_eletronicos.titulos.listagem')
    );
    this.listarRegistros();
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'ProcedimentoEletronico', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    abrirCopiarProcedimento: function (procedimento) {
      this.clienteSelecionado = null;
      this.procedimentoSelecionadoId = procedimento.id;
      this.$refs['modal-clientes'].abrirModal();
      this.$refs['modal-clientes-filho'].inicializar();
      setTimeout(() => {
        this.$refs['modal-clientes-filho'].listar();
      }, 500);
    },
    copiarProcedimento() {
      this.$refs['modal-clientes'].fecharModal();
      this.$store.dispatch('Layout/iniciarCarregamento');
      ProcedimentoEletronicoService.copiarProcedimento(
        this.procedimentoSelecionadoId,
        this.clienteSelecionado.id
      )
        .then((res) => {
          this.toastSucesso(
            this.$t(
              'modulos.procedimentos_eletronicos.copiar_procedimento_eletronico_sucesso',
              { PROC: res.data.codigo, CLIENTE: res.data.participante.nome }
            )
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    abrirUsuarios(item) {
      this.$router.push({
        name: 'procedimento-eletronico-usuarios',
        params: { id: item.id },
      });
    },

    podeSelecionar(item) {
      return (
        item.lastProcedimentoEletronicoUltimaRevisaoViewModel
          .revisaoPublicada === null
      );
    },

    selecionarProcedimentoEletronico(item, selecionado) {
      if (!selecionado) {
        this.tabela.selecionados.splice(
          this.tabela.selecionados.indexOf(
            this.tabela.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.tabela.selecionados.push(item);
      }
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    duplicarProcedimentoEletronico(item) {
      this.$store.dispatch('Layout/iniciarCarregamento');

      ProcedimentoEletronicoService.duplicar(
        item.lastProcedimentoEletronicoRevisaoViewModel.revisaoId
      )
        .then((response) => {
          this.toastSucesso(
            this.$t(
              'modulos.procedimentos_eletronicos.duplicar_procedimento_eletronico_sucesso',
              { PROC: response.data }
            )
          );

          this.listarRegistros();
        })
        .catch(() => {
          this.toastErro(this.$t(`geral.erros.erroGenerico`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ProcedimentoEletronicoService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        let promises = [];

        this.tabela.selecionados.forEach((procedimentoEletronico) => {
          promises.push(
            ProcedimentoEletronicoService.excluir(procedimentoEletronico.id)
          );
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(`modulos.procedimentos_eletronicos.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo() {
      this.$router.push({ name: 'procedimento-eletronico-novo' });
    },
    abrirEditar(item) {
      if (
        item.lastProcedimentoEletronicoRevisaoViewModel.revisao !=
        item.lastProcedimentoEletronicoUltimaRevisaoViewModel.revisaoPublicada
      ) {
        this.$router.push({
          name: 'procedimento-eletronico-editar',
          params: { id: item.id },
        });
        return;
      }
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.procedimentos_eletronicos.alertas.procedimento')
      ).then(async () => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        ProcedimentoEletronicoService.criarRevisao(item.id)
          .then(() => {
            this.$router.push({
              name: 'procedimento-eletronico-editar',
              params: { id: item.id },
            });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirRevisoes(item) {
      this.$router.push({
        name: 'procedimento-eletronico-revisoes',
        params: { id: item.id },
      });
    },
    desenharCertificado(item) {
      if (
        item.revisao !=
        item.lastProcedimentoEletronicoUltimaRevisaoViewModel.revisaoPublicada
      ) {
        this.abrirStimusSoft(item);
        return;
      }
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.procedimentos_eletronicos.alertas.certificado')
      ).then(() => {
        this.abrirStimusSoft(item);
      });
    },
    abrirStimusSoft(item) {
      window.open(
        this.$router.resolve({
          name: 'procedimento-eletronico-certificado',
          params: {
            id: item.lastProcedimentoEletronicoRevisaoViewModel.revisaoId,
          },
        }).href,
        '_blank'
      );
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>

<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
